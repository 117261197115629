<template>
  <div class="informasi-perusahaan">
    <section class="banner">
      <ImageResponsiveBanner
        imageUrl="https://narasi.tv/storage/images/banner-narasi.webp"
        imageUrlMobile="https://narasi.tv/storage/images/banner-narasi-mobile.webp"
        :fromUrl="true"
        width="1000"
        height="233"
      />
    </section>
    <section class="content is-desktop">
      <div class="container">
        <div class="content-nav">
          <a
            v-for="menu in menus"
            :key="menu.slug"
            :href="`${menu.slug === 'karir' ? '/karir' : `/about-us?menu=${menu.slug}`}`"
            class="menu-item"
            :class="
              menu.slug === $route.query.menu
                ? 'Link-exact-active Link-active'
                : ''
            "
          >
            <TitleSection :title="menu.label" />
            <Icons
              name="arrow-right"
              :color="$route.query.menu === menu.slug ? '#34128C' : '#616161'"
            />
          </a>
        </div>
        <div class="content-detail">
          <!-- INFORMASI PERUSAHAAN -->
          <div
            id="informasi-perusahaan"
            v-show="$route.query.menu === 'informasi-perusahaan'"
          >
            <div class="about">
              <h1 class="title">TENTANG NARASI</h1>
              <p class="desc">
                Indonesia tak tersusun dari batas peta, tapi gerak dan peran
                kaum muda. Karena itulah Narasi ada. Menjadi ruang kerja dan
                karya anak bangsa. Menyigi berbagai nama dan peristiwa, sembari
                memberi makna pada segala cerita yang berharga.
                <br /><br />Narasi mengajak Anda menyelami ragam suguhan melalui
                program-program perbincangan, reportase, kemasan dokumenter,
                opini dan ruang interaksi, juga mengelola komunitas, aktivasi
                dan event online dan offline melalui kanal digital narasi.tv.
                Sebab di masa sekarang jejak digital tampil mengemuka. Setiap
                mereka yang aktif menebar karya, energi positif mudah menular
                dan semakin menggelora. <br /><br />Narasi adalah ruang bagi
                kita bersama untuk bertukar ide dan beradu gagasan. Berkiblat
                pada idealisme serta nilai-nilai kemajemukan, kritis dan
                toleran, serta mendorong setiap anak bangsa agar suka rela
                menggagas peran. Sebab setiap zaman menorehkan ceritanya
                sendiri. <br /><br />Semoga apa yang tersaji di Narasi ikut
                memberi warna dan membentuk wajah dan kepribadian negeri.
                <br /><br />Salam dari kami, <br /><br />Narasi People
              </p>
            </div>
            <div class="redaksi">
              <!-- <h1 class="title">Pimpinan Redaksi</h1>
              <h2 class="sub-title pt">PT. Narasi Media Pracaya</h2> -->
              <!-- <h2 class="sub-title">Dewan Redaksi</h2>
              <ul>
                <li>Catharina Davy</li>
                <li>Dahlia Citra Buana</li>
                <li>Jovial da Lopez</li>
                <li>Zen RS</li>
              </ul> -->

              <p>
                <strong>Dewan Redaksi:</strong> Catharina Davy, Dahlia Citra
                Buana, Jovial da Lopez, Zen RS
              </p>

              <p><strong>Pemimpin Redaksi:</strong> Zen RS</p>

              <p>
                <strong>Manajer Pemberitaan:</strong> Laban Laisila, Maulida Sri
                Handayani, Surya Wijayanti
              </p>

              <p>
                <strong>Produser Eksekutif:</strong> Adeste Adipriyanti, Hendri
                Wijayanto, Soni Triantoro, Theresia Santi
              </p>

              <p>
                <strong>Produser:</strong> Akbar Wijaya, Andita Rahma, Arbi
                Sumandoyo, Aqwam Hanifan, Blasius Abram, Chairunnisa, Faisal
                Irfani, Faris Dzaki, Mufti Sholih, Ramadhan Yahya, Randu Dahlia,
                Yulian Muhammad
              </p>

              <p>
                <strong>Reporter:</strong> Debora Mulya, Gempita Surya, Juan
                Robin, Selvina Suryaningsih, Rahma Arifa, Wili Azhari
              </p>

              <p><strong>Kreator Konten:</strong> Vineke Yovanka, Raina Geno</p>

              <p>
                <strong>Jurnalis Video:</strong> Achmad Nur Wahib, Arief Rahman
                Hakim, Haris Setiawan, Wisnu Yogha
              </p>

              <p><strong>Tim Kreatif:</strong> Revi Ekta, Bella Efrilia</p>

              <p>
                <strong>Tim Riset:</strong> Frendy Kurniawan, Islahuddin, Ayu
                Dwi Susanti, Jessica Ruth Andina
              </p>

              <p>
                <strong>Media Sosial:</strong> Suci RIfani (lead), Rizqona
                Fahiqul Ilmi (lead), Irpan Ripandi, Lovenilla Dewi, Mitha
                Sinayang, Sino Wibowo
              </p>

              <p class="desc">
                PT. Narasi Media Pracaya terdaftar dan terverifikasi oleh Dewan
                Pers pada tanggal 29 Nopember 2019 dengan bukti sertifikat
                bernomor : 472/DP-Verifikasi/K/XI/2019. Beroperasi sesuai dengan
                Kode Etik Jurnalistik yang tercantum pada tautan situs resmi
                Dewan Pers di:
                <a
                  href="https://dewanpers.or.id/kebijakan/peraturan"
                  target="_blank"
                  rel="noopener"
                  >https://dewanpers.or.id/kebijakan/peraturan</a
                >
              </p>
            </div>
            <div class="address">
              <h1 class="title">Alamat Redaksi</h1>
              <p class="desc">
                INTILAND TOWER <br /><br />Jl. Jend. Sudirman Kav. 32, Jakarta
                Pusat, 10220 <br /><br />Telp: 021-57930649 <br /><br />Email:
                redaksi@narasi.tv
              </p>
            </div>
          </div>
          <div id="privasi" v-show="$route.query.menu === 'privasi'">
            <p class="desc">Selamat datang di narasi.tv</p>
            <p class="desc">
              Kami berusaha untuk menjaga kepercayaan Anda dengan melindungi
              informasi yang bersifat pribadi dan rahasia. Dengan mengunjungi
              dan mendaftarkan diri sebagai anggota narasi.tv maka pengguna
              setuju untuk terikat dan mematuhi semua syarat dan ketentuan serta
              kebijakan privasi kami. Syarat dan kondisi dapat berubah dari
              waktu ke waktu dan perubahan ini tidak akan diberitahukan kepada
              anda melalui pengumuman pada narasi.tv, karena itu kami berharap
              para pengguna mengecek secara berkala apa yang termuat di sini.
              Jika Anda tidak setuju untuk terikat dan mematuhi semua syarat dan
              ketentuan serta kebijakan privasi kami, jangan mengakses atau
              menggunakan layanan ini. <br /><br />Ketentuan pengguna Narasi
              akan menjelaskan cara kami mengumpulkan, menggunakan, mengungkap
              dan melindungi berbagai informasi yang berhubungan dengan
              penggunaan situs Narasi. Situs ini dimiliki dan dioperasikan oleh
              Narasi.
            </p>
            <h2 class="sub-title">Disclaimer</h2>
            <p class="desc">
              Narasi berhak sepenuhnya untuk memuat, tidak memuat, mengedit,
              dan/ atau menghapus data/ informasi yang disampaikan oleh pembaca,
              disesuaikan dengan kaidah dan nilai yang berlaku yang tercermin
              dalam kanal-kanal narasi.tv. Dan hal ini berlaku mutlak.
            </p>

            <h2 class="sub-title">Ketentuan Informasi Akun Pengguna</h2>
            <p class="desc">
              Narasi mengumpulkan beberapa informasi terkait pengguna yang
              datang ke situs narasi.tv. Pengguna secara sukarela memasukkan
              data diri yang ada di kolom isian. Narasi akan melindungi data
              pribadi Anda dan akan menyimpan rekaman aktivitas pengguna, baik
              dalam menuliskan komentar atau membagikan ke pihak ketiga. Hal ini
              dilakukan semata untuk mengetahui preferensi pengguna.
            </p>

            <h2 class="sub-title">Ketentuan Informasi dari Pihak Ketiga</h2>
            <p class="desc">
              Saat pengguna memasuki/ mendaftarkan data ke Narasi dari pihak
              ketiga (Google/ Twitter/ Facebook dan lain sebagainya), pengguna
              setuju untuk memberi akses data pribadi pihak ketiga tersebut
              kepada Narasi. Data pribadi akan digunakan untuk memperbaiki
              layanan Narasi kepada para pengguna.
            </p>

            <h2 class="sub-title">Ketentuan Data Lokasi</h2>
            <p class="desc">
              Data lokasi meliputi koordinat GPS dan sejenisnya dapat terekam
              oleh layanan Narasi saat pengguna mengakses situs. Data lokasi ini
              menghadirkan informasi mengenai cara pengguna mengakses Narasi
              serta ketepatan layanan untuk pengguna.
            </p>

            <h2 class="sub-title">Ketentuan Komunikasi Komersial</h2>
            <p class="desc">
              Narasi dapat mengirimkan email berupa newsletter dan promosi.
              Terdapat pilihan bagi pengguna yang tidak ingin menerima informasi
              ini.
            </p>

            <h2 class="sub-title">
              Ketentuan Penggunaan Informasi Mengenai Pengguna
            </h2>
            <p class="desc">
              Informasi pengguna berupa cookies, log file, device identifiers,
              data lokasi dan informasi clear gifs Narasi gunakan untuk
              memudahkan pengguna mengakses kembali layanan tanpa perlu
              memasukkan ulang informasi, menyediakan konten dan informasi
              personal. <br /><br />Pengguna harus menyesuaikan pengaturan
              profil. Narasi tidak akan memberikan atau memperjualbelikan
              informasi pengguna kepada pihak ketiga, selain rekanan bisnis
              untuk menyediakan layanan. Informasi publik dapat dilacak dan
              ditemukan oleh pengguna lainnya. Jika ada informasi yang dihapus,
              jejaknya dapat kembali dilihat di halaman cached dan archived
              layanan, atau apabila pengguna lain sudah membuat saduran atau
              menyimpan informasi terkait. <br /><br />Narasi berhak menjual
              atau mengalihkan Narasi beserta saham-sahamnya, maupun kombinasi
              produk, layanan, aset dan/ atau bisnis. Informasi pengguna seperti
              nama, alamat email, user content dan informasi pengguna lainnya
              dapat termasuk dalam transaksi.
            </p>

            <h2 class="sub-title">Pertanyaan</h2>
            <p class="desc">
              Pertanyaan apapun sehubungan dengan kebijakan privasi dapat
              langsung dikirimkan melalui surat elektronik ke:
              redaksi@narasi.tv.
            </p>

            <h2 class="sub-title">Perubahan pada ketentuan pengguna kami</h2>
            <p class="desc">
              Narasi memberikan informasi jika ada perubahan kebijakan privasi.
              Kebijakan privasi ini diperbaharui pada tanggal 23 Februari 2022.
            </p>

            <h2 class="sub-title">Kewajiban</h2>
            <p class="desc">
              Dengan mengakses dan menggunakan layanan ini berarti Anda
              menyatakan dan menjamin untuk tunduk pada hukum dan peraturan di
              wilayah Republik Indonesia dan Anda dengan ini dilarang untuk:
            </p>
            <ul>
              <li>
                Menyinggung, memicu konflik yang melibatkan suku, agama, ras dan
                hubungan antar kelompok.
              </li>
              <li>
                Melanggar hukum, mengancam, kasar, melecehkan, memfitnah,
                mencemarkan, sedang berbohong, curang, atau kebencian pada orang
                atau kelompok tertentu.
              </li>
              <li>
                Melanggar atau menyalahi hak orang lain, termasuk tanpa batasan,
                hak paten, merek dagang, rahasia dagang, hak cipta, publisitas
                atau hak milik lainnya.
              </li>
              <li>
                Menganiaya, melecehkan, merendahkan atau mengintimidasi individu
                atau kelompok individu berdasarkan agama, jenis kelamin,
                orientasi seksual, ras, etnis, usia atau cacat fisik.
              </li>
              <li>Melanggar norma kesusilaan, cabul, pornografi.</li>
              <li>
                Menganjurkan atau menyarankan tindakan yang melanggar hukum.
              </li>
              <li>
                Memuat kata-kata atau gambar yang kasar, kotor, jorok, dan
                sumpah serapah.
              </li>
              <li>
                Menyebarkan ideologi tertentu atau doktrin yang pada prinsipnya
                dilarang oleh hukum yang berlaku di wilayah Republik Indonesia.
              </li>
              <li>
                Menyebarkan file atau program yang berisi virus atau kode
                komputer lainnya atau file atau program yang merusak, mengganggu
                atau membatasi fungsi dari software atau hardware komputer atau
                peralatan komunikasi, atau memperbolehkan penggunaan komputer
                atau jaringan komputer yang tidak sah.
              </li>
              <li>
                Melanggar Syarat dan Ketentuan, pedoman atau kebijakan yang ada
                di narasi.tv
              </li>
            </ul>

            <h2 class="sub-title">Hukum</h2>
            <p class="desc">
              Syarat-syarat dan kondisi dalam kebijakan privasi ini tunduk
              kepada kode etik jurnalistik yang berlaku di wilayah Republik
              Indonesia.
            </p>
          </div>
          <!-- SYARAT DAN KETENTUAN UMUM -->
          <div
            id="syarat-dan-ketentuan-umum"
            v-show="$route.query.menu === 'syarat-dan-ketentuan-umum'"
          >
            <h2 class="sub-title">Syarat dan Ketentuan Umum</h2>
            <h2 class="sub-title">Pendahuluan</h2>
            <ul>
              <li>
                Terima kasih atas kunjungan Anda ke Website Kami, narasi.tv.
                Kami berharap agar kunjungan Anda dapat bermanfaat dan memberi
                kenyamanan dalam mengakses dan menggunakan seluruh Layanan yang
                tersedia di Website Kami. Kami terus menerus berupaya
                memperbaiki dan meningkatkan mutu pelayanan Kami, dan sangat
                menghargai segala kritik, saran dan masukan dari Anda. Silahkan
                Anda menyampaikannya ke Kami melalui surel di halo@narasi.tv.
              </li>
              <li>
                Website ini dimiliki, dioperasikan dan diselenggarakan oleh PT.
                Narasi Citra Sahwahita (“Kami” atau “Narasi”), perseroan
                terbatas yang berdiri atas dasar hukum Republik Indonesia.
                Website Kami tersedia secara online melalui Website: narasi.tv
                atau berbagai akses, media, perangkat dan platform lainnya, baik
                yang sudah atau akan tersedia di kemudian hari.
              </li>
            </ul>

            <h2 class="sub-title">Umum</h2>
            <ul>
              <li>
                Dengan mengakses dan menggunakan Website dan Layanan Kami, Anda
                menyatakan telah membaca, memahami, menyetujui dan menyatakan
                tunduk pada Syarat dan Ketentuan Umum ini.
              </li>
              <li>
                Syarat dan Ketentuan Umum ini terdiri atas (i) Syarat dan
                Ketentuan Umum yang berlaku untuk setiap akses yang tersedia
                pada Website, dan (ii) syarat dan ketentuan khusus yang mengatur
                lebih lanjut ketentuan penggunaan produk atau Layanan tertentu.
              </li>
              <li>
                Syarat dan Ketentuan Umum dapat Kami ubah, modifikasi, tambah,
                hapus atau koreksi (“perubahan”) setiap saat sesuai dengan
                pengembangan Website dan peraturan perundang-undangan. Anda Kami
                anjurkan untuk mengunjungi Website Kami secara berkala agar
                dapat mengetahui adanya perubahan tersebut.
              </li>
            </ul>

            <h2 class="sub-title">Penggunaan</h2>
            <ul>
              <li>
                Website ini dan produk-produk, teknologi dan proses yang
                terdapat atau terkandung dalam Website, dimiliki oleh Kami atau
                pihak ketiga yang memberi hak kepada Kami. Kecuali untuk
                penggunaan yang secara tegas diijinkan dan diperbolehkan dalam
                Syarat dan Ketentuan Umum ini, Anda tidak memiliki ataupun
                menerima dan Narasi tidak memberikan hak lain apapun ke Anda
                atas Website ini, berikut dengan segala data, informasi dan
                konten di dalamnya.
              </li>
              <li>
                Dengan menggunakan Website ini atau Layanan yang tersedia
                didalamnya, maka Anda menyatakan setuju tidak akan men-download,
                menayangkan atau mentransmisi dengan cara apa pun, dan atau
                membuat konten apapun tersedia untuk umum yang tidak konsisten
                dengan penggunaan yang diizinkan dalam Syarat dan Ketentuan Umum
                ini.
              </li>
              <li>
                Dalam Website ini mungkin terdapat link (tautan) ke website yang
                dikelola oleh pihak ketiga (“Situs Eksternal”) yang melakukan
                kerjasama dengan Kami.
              </li>
              <li>
                Kami tidak mengoperasikan, mengendalikan atau mendukung dalam
                bentuk apapun Situs Eksternal yang bersangkutan ataupun
                konten/isinya. Anda bertanggung jawab penuh atas penggunaan
                Situs Eksternal tersebut dan dianjurkan untuk mempelajari syarat
                dan ketentuan dari Situs Eksternal itu secara seksama.
              </li>
              <li>
                Layanan yang tersedia dalam Website ini secara umum menggunakan
                sistem re-marketing dan sistem cookies yang memungkinkan pihak
                ketiga (termasuk dan tidak terbatas pada Google) mengakses dan
                menggunakan data kunjungan dalam sistem Cookies website ini
                untuk menampilkan dan menayangkan kembali tiap iklan Narasi
                melalui internet.
              </li>
              <li>
                Anda tidak boleh membuat link, melakukan screen capture atau
                data crawling ke Website tanpa adanya persetujuan tertulis
                sebelumnya dari Narasi. Hal-hal tersebut dianggap sebagai
                pelanggaran hak milik intelektual Narasi.
              </li>
            </ul>

            <h2 class="sub-title">Konten</h2>
            <ul>
              <li>
                Semua materi di dalam Website tidak boleh disalin, diproduksi
                ulang, dipublikasikan, dan disebarluaskan baik berupa informasi,
                data, gambar, foto, logo dan lainnya secara utuh maupun sebagian
                dengan cara apapun dan melalui media apapun kecuali untuk
                keperluan pribadi tanpa persetujuan tertulis dari Narasi.
              </li>
              <li>
                Website ini berikut seluruh materinya tidak boleh dimanfaatkan
                untuk hal-hal yang bertujuan melanggar hukum atau norma agama
                dan masyarakat atau hak asasi manusia.
              </li>
            </ul>

            <h2 class="sub-title">Pencabutan akses dan Penghapusan data</h2>
            <ul>
              <li>
                Untuk menghapus akses pihak ketiga, data dan aktivitas anda di
                Narasi.tv, anda dapat mengajukan permintaan penghapusan data ke
                halo@narasi.tv.
              </li>
            </ul>
          </div>
          <!-- PEDOMAN MEDIA SIBER -->
          <div
            id="pedoman-media-siber"
            v-show="$route.query.menu === 'pedoman-media-siber'"
          >
            <p class="desc">
              Kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan
              pers adalah hak asasi manusia yang dilindungi Pancasila,
              Undang-Undang Dasar 1945, dan Deklarasi Universal Hak Asasi
              Manusia PBB. Keberadaan media siber di Indonesia juga merupakan
              bagian dari kemerdekaan berpendapat, kemerdekaan berekspresi, dan
              kemerdekaan pers.<br /><br />
              Media siber memiliki karakter khusus sehingga memerlukan pedoman
              agar pengelolaannya dapat dilaksanakan secara profesional,
              memenuhi fungsi, hak, dan kewajibannya sesuai Undang-Undang Nomor
              40 Tahun 1999 tentang Pers dan Kode Etik Jurnalistik. Untuk itu
              Dewan Pers bersama organisasi pers, pengelola media siber, dan
              masyarakat menyusun Pedoman Pemberitaan Media Siber sebagai
              berikut<br /><br />
              Kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan
              pers adalah hak asasi manusia yang dilindungi Pancasila,
              Undang-Undang Dasar 1945, dan Deklarasi Universal Hak Asasi
              Manusia PBB. Keberadaan media siber di Indonesia juga merupakan
              bagian dari kemerdekaan berpendapat, kemerdekaan berekspresi, dan
              kemerdekaan pers.<br /><br />
              Media siber memiliki karakter khusus sehingga memerlukan pedoman
              agar pengelolaannya dapat dilaksanakan secara profesional,
              memenuhi fungsi, hak, dan kewajibannya sesuai Undang-Undang Nomor
              40 Tahun 1999 tentang Pers dan Kode Etik Jurnalistik. Untuk itu
              Dewan Pers bersama organisasi pers, pengelola media siber, dan
              masyarakat menyusun Pedoman Pemberitaan Media Siber sebagai
              berikut
            </p>

            <h2 class="sub-title">Ruang Lingkup</h2>
            <p class="desc">
              Media Siber adalah segala bentuk media yang menggunakan wahana
              internet dan melaksanakan kegiatan jurnalistik, serta memenuhi
              persyaratan Undang-Undang Pers dan Standar Perusahaan Pers yang
              ditetapkan Dewan Pers. Isi Buatan Pengguna (User Generated
              Content) adalah segala isi yang dibuat dan atau dipublikasikan
              oleh pengguna media siber, antara lain, artikel, gambar, komentar,
              suara, video dan berbagai bentuk unggahan yang melekat pada media
              siber, seperti blog, forum, komentar pembaca atau pemirsa, dan
              bentuk lain.
            </p>

            <h2 class="sub-title">Verifikasi dan Keberimbangan Berita</h2>
            <p class="desc">
              Pada prinsipnya setiap berita harus melalui verifikasi. Berita
              yang dapat merugikan pihak lain memerlukan verifikasi pada berita
              yang sama untuk memenuhi prinsip akurasi dan keberimbangan.
            </p>

            <p class="desc">
              Ketentuan dalam butir (a) di atas dikecualikan, dengan syarat:
            </p>
            <ul>
              <li>
                Berita benar-benar mengandung kepentingan publik yang bersifat
                mendesak;
              </li>
              <li>
                Sumber berita yang pertama adalah sumber yang jelas disebutkan
                identitasnya, kredibel dan kompeten;
              </li>
              <li>
                Subyek berita yang harus dikonfirmasi tidak diketahui
                keberadaannya dan atau tidak dapat diwawancarai;
              </li>
              <li>
                Media memberikan penjelasan kepada pembaca bahwa berita tersebut
                masih memerlukan verifikasi lebih lanjut yang diupayakan dalam
                waktu secepatnya. Penjelasan dimuat pada bagian akhir dari
                berita yang sama, di dalam kurung dan menggunakan huruf miring.
              </li>
            </ul>

            <p class="desc">
              Setelah memuat berita sesuai dengan butir (c), media wajib
              meneruskan upaya verifikasi, dan setelah verifikasi didapatkan,
              hasil verifikasi dicantumkan pada berita pemutakhiran (update)
              dengan tautan pada berita yang belum terverifikasi.
            </p>

            <h2 class="sub-title">
              Isi Buatan Pengguna (User Generated Content)
            </h2>
            <p class="desc">
              Media siber wajib mencantumkan syarat dan ketentuan mengenai Isi
              Buatan Pengguna yang tidak bertentangan dengan Undang-Undang No.
              40 tahun 1999 tentang Pers dan Kode Etik Jurnalistik, yang
              ditempatkan secara terang dan jelas. Media siber mewajibkan setiap
              pengguna untuk melakukan registrasi keanggotaan dan melakukan
              proses log-in terlebih dahulu untuk dapat mempublikasikan semua
              bentuk Isi Buatan Pengguna. Ketentuan mengenai log-in akan diatur
              lebih lanjut.
            </p>

            <p class="desc">
              Dalam registrasi tersebut, media siber mewajibkan pengguna memberi
              persetujuan tertulis bahwa Isi Buatan Pengguna yang
              dipublikasikan:
            </p>
            <ul>
              <li>Tidak memuat isi bohong, fitnah, sadis dan cabul;</li>
              <li>
                Tidak memuat isi yang mengandung prasangka dan kebencian terkait
                dengan suku, agama, ras, dan antargolongan (SARA), serta
                menganjurkan tindakan kekerasan;
              </li>
              <li>
                Tidak memuat isi diskriminatif atas dasar perbedaan jenis
                kelamin dan bahasa, serta tidak merendahkan martabat orang
                lemah, miskin, sakit, cacat jiwa, atau cacat jasmani.
              </li>
            </ul>

            <p class="desc">
              Media siber wajib menyediakan mekanisme pengaduan Isi Buatan
              Pengguna yang dinilai melanggar ketentuan pada butir (c).
              Mekanisme tersebut harus disediakan di tempat yang dengan mudah
              dapat diakses pengguna.<br /><br />
              Media siber wajib menyunting, menghapus, dan melakukan tindakan
              koreksi setiap Isi Buatan Pengguna yang dilaporkan dan melanggar
              ketentuan butir (c), sesegera mungkin secara proporsional
              selambat-lambatnya 2 x 24 jam setelah pengaduan diterima.<br /><br />
              Media siber yang telah memenuhi ketentuan pada butir (a), (b),
              (c), dan (f) tidak dibebani tanggung jawab atas masalah yang
              ditimbulkan akibat pemuatan isi yang melanggar ketentuan pada
              butir (c).<br /><br />
              Media siber bertanggung jawab atas Isi Buatan Pengguna yang
              dilaporkan bila tidak mengambil tindakan koreksi setelah batas
              waktu sebagaimana tersebut pada butir (f).
            </p>

            <h2 class="sub-title">Ralat, Koreksi, dan Hak Jawab</h2>
            <p class="desc">
              Ralat, koreksi, dan hak jawab mengacu pada Undang-Undang Pers,
              Kode Etik Jurnalistik, dan Pedoman Hak Jawab yang ditetapkan Dewan
              Pers.
              <br /><br />
              Ralat, koreksi dan atau hak jawab wajib ditautkan pada berita yang
              diralat, dikoreksi atau yang diberi hak jawab.
              <br /><br />
              Di setiap berita ralat, koreksi, dan hak jawab wajib dicantumkan
              waktu pemuatan ralat, koreksi, dan atau hak jawab tersebut.
              <br /><br />
              Bila suatu berita media siber tertentu disebarluaskan media siber
              lain, maka:
            </p>
            <ul>
              <li>
                Tanggung jawab media siber pembuat berita terbatas pada berita
                yang dipublikasikan di media siber tersebut atau media siber
                yang berada di bawah otoritas teknisnya;
              </li>
              <li>
                Koreksi berita yang dilakukan oleh sebuah media siber, juga
                harus dilakukan oleh media siber lain yang mengutip berita dari
                media siber yang dikoreksi itu;
              </li>
              <li>
                Media yang menyebarluaskan berita dari sebuah media siber dan
                tidak melakukan koreksi atas berita sesuai yang dilakukan oleh
                media siber pemilik dan atau pembuat berita tersebut,
                bertanggung jawab penuh atas semua akibat hukum dari berita yang
                tidak dikoreksinya itu.
              </li>
            </ul>

            <p class="desc">
              Sesuai dengan Undang-Undang Pers, media siber yang tidak melayani
              hak jawab dapat dijatuhi sanksi hukum pidana denda paling banyak
              Rp500.000.000 (Lima ratus juta rupiah).
            </p>

            <h2 class="sub-title">Pencabutan Berita</h2>
            <p class="desc">
              Berita yang sudah dipublikasikan tidak dapat dicabut karena alasan
              penyensoran dari pihak luar redaksi, kecuali terkait masalah SARA,
              kesusilaan, masa depan anak, pengalaman traumatik korban atau
              berdasarkan pertimbangan khusus lain yang ditetapkan Dewan Pers.
              <br /><br />
              Media siber lain wajib mengikuti pencabutan kutipan berita dari
              media asal yang telah dicabut.
              <br /><br />
              Pencabutan berita wajib disertai dengan alasan pencabutan dan
              diumumkan kepada publik.
            </p>

            <h2 class="sub-title">Iklan</h2>
            <p class="desc">
              Media siber wajib membedakan dengan tegas antara produk berita dan
              iklan. Setiap berita/artikel/isi yang merupakan iklan dan atau isi
              berbayar wajib mencantumkan keterangan 'advertorial', 'iklan',
              'ads', 'sponsored', atau kata lain yang menjelaskan bahwa
              berita/artikel/isi tersebut adalah iklan.
            </p>

            <h2 class="sub-title">Hak Cipta</h2>
            <p class="desc">
              Media siber wajib menghormati hak cipta sebagaimana diatur dalam
              peraturan perundang-undangan yang berlaku.
            </p>

            <h2 class="sub-title">Pencantuman Pedoman</h2>
            <p class="desc">
              Media siber wajib mencantumkan Pedoman Pemberitaan Media Siber ini
              di medianya secara terang dan jelas.
            </p>

            <h2 class="sub-title">Sengketa</h2>
            <p class="desc">
              Penilaian akhir atas sengketa mengenai pelaksanaan Pedoman
              Pemberitaan Media Siber ini diselesaikan oleh Dewan Pers.
              <br /><br />
              Jakarta, 3 Februari 2012 (Peraturan Dewan Pers Nomor:
              1/Peraturan-DP/III/2012 Tentang Pedoman Pemberitaan Media Siber)
            </p>
          </div>
          <!-- KONTAK KAMI -->
          <div id="kontak-kami" v-show="$route.query.menu === 'kontak-kami'">
            <div class="desc">
              <p class="organitation">PT NARASI CITRA SAHWAHITA</p>
              <span>INTILAND TOWER LT. 20</span>
              <p>Jl. Jend. Sudirman Kav. 32, Jakarta Pusat, 10220</p>
              <p class="m-0">
                <strong
                  >Telp: <a href="tel:02157939176">021-57930649</a></strong
                >
              </p>
              <!-- <p class="m-0">
                <strong>Fax: <a href="fax:02157930649">021-57930649</a></strong>
              </p> -->
              <p class="m-0">
                <strong
                  >Email:
                  <a href="mailto:corcomm@narasi.tv"
                    >corcomm@narasi.tv</a
                  ></strong
                >
              </p>
              <p>Networking and Communications Management System Narasi</p>
            </div>
            <div class="social-media">
              <a
                href="https://www.instagram.com/wmnlyfe/"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">WMN By <br />Narasi</p>
              </a>
              <a
                href="https://www.instagram.com/komunitasnarasi/"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Komunitas <br />Narasi</p>
              </a>
              <a
                href="https://www.instagram.com/narasi.ecosystem/"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Narasi <br />Ecosystem</p>
              </a>
              <a
                href="https://www.instagram.com/ceritadinarasi/"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Cerita Di <br />Narasi</p>
              </a>
              <!-- <a
                href="https://www.instagram.com/getstarted.narasi/?hl=en"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Get Started <br />Narasi</p>
              </a> -->
              <a
                href="https://www.instagram.com/narasiacademy/"
                target="_blank"
                rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Narasi <br />Academy</p>
              </a>
              <!-- <a
                href="https://www.instagram.com/narasipeople/?hl=en"
                target="_blank" rel="noopener"
                class="item"
              >
                <Icons name="instagram-about-us" />
                <p class="desc">Narasi <br />People</p>
              </a> -->
            </div>
          </div>
          <!-- karir -->
          <div id="karir" v-show="$route.query.menu === 'karir'">
            <Link :to="'/karir'" class="btn-karir">
              FULLTIME POSITION
              <Icons name="bag" />
            </Link>
            <Link :to="'/karir'" class="btn-karir">
              INTERN POSITION
              <Icons name="bag"
            /></Link>
          </div>
        </div>
      </div>
    </section>
    <section class="content is-mobile">
      <div class="container">
        <div class="accordion" id="accordion-about-us">
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_informasi_perusahaan"
              aria-expanded="false"
              aria-controls="collapse_informasi_perusahaan"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'informasi-perusahaan',
                  },
                })
              "
            >
              <TitleSection title="INFORMASI PERUSAHAAN" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_informasi_perusahaan"
              :class="{ show: $route.query.menu === 'informasi-perusahaan' }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="informasi-perusahaan" class="accordion-content">
                  <div class="about">
                    <h1 class="title">TENTANG NARASI</h1>
                    <p class="desc">
                      Indonesia tak tersusun dari batas peta, tapi gerak dan
                      peran kaum muda. Karena itulah Narasi ada. Menjadi ruang
                      kerja dan karya anak bangsa. Menyigi berbagai nama dan
                      peristiwa, sembari memberi makna pada segala cerita yang
                      berharga.
                      <br /><br />Narasi mengajak Anda menyelami ragam suguhan
                      melalui program-program perbincangan, reportase, kemasan
                      dokumenter, opini dan ruang interaksi, juga mengelola
                      komunitas, aktivasi dan event online dan offline melalui
                      kanal digital narasi.tv. Sebab di masa sekarang jejak
                      digital tampil mengemuka. Setiap mereka yang aktif menebar
                      karya, energi positif mudah menular dan semakin
                      menggelora. <br /><br />Narasi adalah ruang bagi kita
                      bersama untuk bertukar ide dan beradu gagasan. Berkiblat
                      pada idealisme serta nilai-nilai kemajemukan, kritis dan
                      toleran, serta mendorong setiap anak bangsa agar suka rela
                      menggagas peran. Sebab setiap zaman menorehkan ceritanya
                      sendiri. <br /><br />Semoga apa yang tersaji di Narasi
                      ikut memberi warna dan membentuk wajah dan kepribadian
                      negeri. <br /><br />Salam dari kami, <br /><br />Narasi
                      People
                    </p>
                  </div>
                  <div class="redaksi">
                    <!-- <h1 class="title">Pimpinan Redaksi</h1>
              <h2 class="sub-title pt">PT. Narasi Media Pracaya</h2> -->
                    <!-- <h2 class="sub-title">Dewan Redaksi</h2>
              <ul>
                <li>Catharina Davy</li>
                <li>Dahlia Citra Buana</li>
                <li>Jovial da Lopez</li>
                <li>Zen RS</li>
              </ul> -->

                    <p>
                      <strong>Dewan Redaksi:</strong> Catharina Davy, Dahlia
                      Citra Buana, Jovial da Lopez, Zen RS
                    </p>

                    <p><strong>Pemimpin Redaksi:</strong> Zen RS</p>

                    <p>
                      <strong>Manajer Pemberitaan:</strong> Laban Laisila,
                      Maulida Sri Handayani, Surya Wijayanti
                    </p>

                    <p>
                      <strong>Produser Eksekutif:</strong> Adeste Adipriyanti,
                      Hendri Wijayanto, Soni Triantoro, Theresia Santi
                    </p>

                    <p>
                      <strong>Produser:</strong> Akbar Wijaya, Andita Rahma,
                      Arbi Sumandoyo, Aqwam Hanifan, Blasius Abram, Chairunnisa,
                      Faisal Irfani, Faris Dzaki, Mufti Sholih, Ramadhan Yahya,
                      Randu Dahlia, Yulian Muhammad
                    </p>

                    <p>
                      <strong>Reporter:</strong> Debora Mulya, Gempita Surya,
                      Juan Robin, Selvina Suryaningsih, Rahma Arifa, Wili Azhari
                    </p>

                    <p>
                      <strong>Kreator Konten:</strong> Vineke Yovanka, Raina
                      Geno
                    </p>

                    <p>
                      <strong>Jurnalis Video:</strong> Achmad Nur Wahib, Arief
                      Rahman Hakim, Haris Setiawan, Wisnu Yogha
                    </p>

                    <p>
                      <strong>Tim Kreatif:</strong> Revi Ekta, Bella Efrilia
                    </p>

                    <p>
                      <strong>Tim Riset:</strong> Frendy Kurniawan, Islahuddin,
                      Ayu Dwi Susanti, Jessica Ruth Andina
                    </p>

                    <p>
                      <strong>Media Sosial:</strong> Suci RIfani (lead), Rizqona
                      Fahiqul Ilmi (lead), Irpan Ripandi, Lovenilla Dewi, Mitha
                      Sinayang, Sino Wibowo
                    </p>

                    <p class="desc">
                      PT. Narasi Media Pracaya terdaftar dan terverifikasi oleh
                      Dewan Pers pada tanggal 29 Nopember 2019 dengan bukti
                      sertifikat bernomor : 472/DP-Verifikasi/K/XI/2019.
                      Beroperasi sesuai dengan Kode Etik Jurnalistik yang
                      tercantum pada tautan situs resmi Dewan Pers di:
                      <a
                        href="https://dewanpers.or.id/kebijakan/peraturan"
                        target="_blank"
                        rel="noopener"
                        >https://dewanpers.or.id/kebijakan/peraturan</a
                      >
                    </p>
                  </div>
                  <div class="address">
                    <h1 class="title">Alamat Redaksi</h1>
                    <p class="desc">
                      INTILAND TOWER <br /><br />Jl. Jend. Sudirman Kav. 32,
                      Jakarta Pusat, 10220 <br /><br />Telp: 021-57930649
                      <br /><br />Email: redaksi@narasi.tv
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_privasi"
              aria-expanded="false"
              aria-controls="collapse_privasi"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'privasi',
                  },
                })
              "
            >
              <TitleSection title="PRIVASI" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_privasi"
              :class="{ show: $route.query.menu === 'privasi' }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="privasi" class="accordion-content">
                  <p class="desc">Selamat datang di narasi.tv</p>
                  <p class="desc">
                    Kami berusaha untuk menjaga kepercayaan Anda dengan
                    melindungi informasi yang bersifat pribadi dan rahasia.
                    Dengan mengunjungi dan mendaftarkan diri sebagai anggota
                    narasi.tv maka pengguna setuju untuk terikat dan mematuhi
                    semua syarat dan ketentuan serta kebijakan privasi kami.
                    Syarat dan kondisi dapat berubah dari waktu ke waktu dan
                    perubahan ini tidak akan diberitahukan kepada anda melalui
                    pengumuman pada narasi.tv, karena itu kami berharap para
                    pengguna mengecek secara berkala apa yang termuat di sini.
                    Jika Anda tidak setuju untuk terikat dan mematuhi semua
                    syarat dan ketentuan serta kebijakan privasi kami, jangan
                    mengakses atau menggunakan layanan ini.
                    <br /><br />Ketentuan pengguna Narasi akan menjelaskan cara
                    kami mengumpulkan, menggunakan, mengungkap dan melindungi
                    berbagai informasi yang berhubungan dengan penggunaan situs
                    Narasi. Situs ini dimiliki dan dioperasikan oleh Narasi.
                  </p>
                  <h2 class="sub-title">Disclaimer</h2>
                  <p class="desc">
                    Narasi berhak sepenuhnya untuk memuat, tidak memuat,
                    mengedit, dan/ atau menghapus data/ informasi yang
                    disampaikan oleh pembaca, disesuaikan dengan kaidah dan
                    nilai yang berlaku yang tercermin dalam kanal-kanal
                    narasi.tv. Dan hal ini berlaku mutlak.
                  </p>

                  <h2 class="sub-title">Ketentuan Informasi Akun Pengguna</h2>
                  <p class="desc">
                    Narasi mengumpulkan beberapa informasi terkait pengguna yang
                    datang ke situs narasi.tv. Pengguna secara sukarela
                    memasukkan data diri yang ada di kolom isian. Narasi akan
                    melindungi data pribadi Anda dan akan menyimpan rekaman
                    aktivitas pengguna, baik dalam menuliskan komentar atau
                    membagikan ke pihak ketiga. Hal ini dilakukan semata untuk
                    mengetahui preferensi pengguna.
                  </p>

                  <h2 class="sub-title">
                    Ketentuan Informasi dari Pihak Ketiga
                  </h2>
                  <p class="desc">
                    Saat pengguna memasuki/ mendaftarkan data ke Narasi dari
                    pihak ketiga (Google/ Twitter/ Facebook dan lain
                    sebagainya), pengguna setuju untuk memberi akses data
                    pribadi pihak ketiga tersebut kepada Narasi. Data pribadi
                    akan digunakan untuk memperbaiki layanan Narasi kepada para
                    pengguna.
                  </p>

                  <h2 class="sub-title">Ketentuan Data Lokasi</h2>
                  <p class="desc">
                    Data lokasi meliputi koordinat GPS dan sejenisnya dapat
                    terekam oleh layanan Narasi saat pengguna mengakses situs.
                    Data lokasi ini menghadirkan informasi mengenai cara
                    pengguna mengakses Narasi serta ketepatan layanan untuk
                    pengguna.
                  </p>

                  <h2 class="sub-title">Ketentuan Komunikasi Komersial</h2>
                  <p class="desc">
                    Narasi dapat mengirimkan email berupa newsletter dan
                    promosi. Terdapat pilihan bagi pengguna yang tidak ingin
                    menerima informasi ini.
                  </p>

                  <h2 class="sub-title">
                    Ketentuan Penggunaan Informasi Mengenai Pengguna
                  </h2>
                  <p class="desc">
                    Informasi pengguna berupa cookies, log file, device
                    identifiers, data lokasi dan informasi clear gifs Narasi
                    gunakan untuk memudahkan pengguna mengakses kembali layanan
                    tanpa perlu memasukkan ulang informasi, menyediakan konten
                    dan informasi personal. <br /><br />Pengguna harus
                    menyesuaikan pengaturan profil. Narasi tidak akan memberikan
                    atau memperjualbelikan informasi pengguna kepada pihak
                    ketiga, selain rekanan bisnis untuk menyediakan layanan.
                    Informasi publik dapat dilacak dan ditemukan oleh pengguna
                    lainnya. Jika ada informasi yang dihapus, jejaknya dapat
                    kembali dilihat di halaman cached dan archived layanan, atau
                    apabila pengguna lain sudah membuat saduran atau menyimpan
                    informasi terkait. <br /><br />Narasi berhak menjual atau
                    mengalihkan Narasi beserta saham-sahamnya, maupun kombinasi
                    produk, layanan, aset dan/ atau bisnis. Informasi pengguna
                    seperti nama, alamat email, user content dan informasi
                    pengguna lainnya dapat termasuk dalam transaksi.
                  </p>

                  <h2 class="sub-title">Pertanyaan</h2>
                  <p class="desc">
                    Pertanyaan apapun sehubungan dengan kebijakan privasi dapat
                    langsung dikirimkan melalui surat elektronik ke:
                    redaksi@narasi.tv.
                  </p>

                  <h2 class="sub-title">
                    Perubahan pada ketentuan pengguna kami
                  </h2>
                  <p class="desc">
                    Narasi memberikan informasi jika ada perubahan kebijakan
                    privasi. Kebijakan privasi ini diperbaharui pada tanggal 23
                    Februari 2022.
                  </p>

                  <h2 class="sub-title">Kewajiban</h2>
                  <p class="desc">
                    Dengan mengakses dan menggunakan layanan ini berarti Anda
                    menyatakan dan menjamin untuk tunduk pada hukum dan
                    peraturan di wilayah Republik Indonesia dan Anda dengan ini
                    dilarang untuk:
                  </p>
                  <ul>
                    <li>
                      Menyinggung, memicu konflik yang melibatkan suku, agama,
                      ras dan hubungan antar kelompok.
                    </li>
                    <li>
                      Melanggar hukum, mengancam, kasar, melecehkan, memfitnah,
                      mencemarkan, sedang berbohong, curang, atau kebencian pada
                      orang atau kelompok tertentu.
                    </li>
                    <li>
                      Melanggar atau menyalahi hak orang lain, termasuk tanpa
                      batasan, hak paten, merek dagang, rahasia dagang, hak
                      cipta, publisitas atau hak milik lainnya.
                    </li>
                    <li>
                      Menganiaya, melecehkan, merendahkan atau mengintimidasi
                      individu atau kelompok individu berdasarkan agama, jenis
                      kelamin, orientasi seksual, ras, etnis, usia atau cacat
                      fisik.
                    </li>
                    <li>Melanggar norma kesusilaan, cabul, pornografi.</li>
                    <li>
                      Menganjurkan atau menyarankan tindakan yang melanggar
                      hukum.
                    </li>
                    <li>
                      Memuat kata-kata atau gambar yang kasar, kotor, jorok, dan
                      sumpah serapah.
                    </li>
                    <li>
                      Menyebarkan ideologi tertentu atau doktrin yang pada
                      prinsipnya dilarang oleh hukum yang berlaku di wilayah
                      Republik Indonesia.
                    </li>
                    <li>
                      Menyebarkan file atau program yang berisi virus atau kode
                      komputer lainnya atau file atau program yang merusak,
                      mengganggu atau membatasi fungsi dari software atau
                      hardware komputer atau peralatan komunikasi, atau
                      memperbolehkan penggunaan komputer atau jaringan komputer
                      yang tidak sah.
                    </li>
                    <li>
                      Melanggar Syarat dan Ketentuan, pedoman atau kebijakan
                      yang ada di narasi.tv
                    </li>
                  </ul>

                  <h2 class="sub-title">Hukum</h2>
                  <p class="desc">
                    Syarat-syarat dan kondisi dalam kebijakan privasi ini tunduk
                    kepada kode etik jurnalistik yang berlaku di wilayah
                    Republik Indonesia.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_tnc"
              aria-expanded="false"
              aria-controls="collapse_tnc"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'syarat-dan-ketentuan-umum',
                  },
                })
              "
            >
              <TitleSection title="SYARAT DAN KETENTUAN UMUM" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_tnc"
              :class="{
                show: $route.query.menu === 'syarat-dan-ketentuan-umum',
              }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="syarat-dan-ketentuan-umum" class="accordion-content">
                  <h2 class="sub-title">Syarat dan Ketentuan Umum</h2>
                  <h2 class="sub-title">Pendahuluan</h2>
                  <ul>
                    <li>
                      Terima kasih atas kunjungan Anda ke Website Kami,
                      narasi.tv. Kami berharap agar kunjungan Anda dapat
                      bermanfaat dan memberi kenyamanan dalam mengakses dan
                      menggunakan seluruh Layanan yang tersedia di Website Kami.
                      Kami terus menerus berupaya memperbaiki dan meningkatkan
                      mutu pelayanan Kami, dan sangat menghargai segala kritik,
                      saran dan masukan dari Anda. Silahkan Anda menyampaikannya
                      ke Kami melalui surel di halo@narasi.tv.
                    </li>
                    <li>
                      Website ini dimiliki, dioperasikan dan diselenggarakan
                      oleh PT. Narasi Citra Sahwahita (“Kami” atau “Narasi”),
                      perseroan terbatas yang berdiri atas dasar hukum Republik
                      Indonesia. Website Kami tersedia secara online melalui
                      Website: narasi.tv atau berbagai akses, media, perangkat
                      dan platform lainnya, baik yang sudah atau akan tersedia
                      di kemudian hari.
                    </li>
                  </ul>

                  <h2 class="sub-title">Umum</h2>
                  <ul>
                    <li>
                      Dengan mengakses dan menggunakan Website dan Layanan Kami,
                      Anda menyatakan telah membaca, memahami, menyetujui dan
                      menyatakan tunduk pada Syarat dan Ketentuan Umum ini.
                    </li>
                    <li>
                      Syarat dan Ketentuan Umum ini terdiri atas (i) Syarat dan
                      Ketentuan Umum yang berlaku untuk setiap akses yang
                      tersedia pada Website, dan (ii) syarat dan ketentuan
                      khusus yang mengatur lebih lanjut ketentuan penggunaan
                      produk atau Layanan tertentu.
                    </li>
                    <li>
                      Syarat dan Ketentuan Umum dapat Kami ubah, modifikasi,
                      tambah, hapus atau koreksi (“perubahan”) setiap saat
                      sesuai dengan pengembangan Website dan peraturan
                      perundang-undangan. Anda Kami anjurkan untuk mengunjungi
                      Website Kami secara berkala agar dapat mengetahui adanya
                      perubahan tersebut.
                    </li>
                  </ul>

                  <h2 class="sub-title">Penggunaan</h2>
                  <ul>
                    <li>
                      Website ini dan produk-produk, teknologi dan proses yang
                      terdapat atau terkandung dalam Website, dimiliki oleh Kami
                      atau pihak ketiga yang memberi hak kepada Kami. Kecuali
                      untuk penggunaan yang secara tegas diijinkan dan
                      diperbolehkan dalam Syarat dan Ketentuan Umum ini, Anda
                      tidak memiliki ataupun menerima dan Narasi tidak
                      memberikan hak lain apapun ke Anda atas Website ini,
                      berikut dengan segala data, informasi dan konten di
                      dalamnya.
                    </li>
                    <li>
                      Dengan menggunakan Website ini atau Layanan yang tersedia
                      didalamnya, maka Anda menyatakan setuju tidak akan
                      men-download, menayangkan atau mentransmisi dengan cara
                      apa pun, dan atau membuat konten apapun tersedia untuk
                      umum yang tidak konsisten dengan penggunaan yang diizinkan
                      dalam Syarat dan Ketentuan Umum ini.
                    </li>
                    <li>
                      Dalam Website ini mungkin terdapat link (tautan) ke
                      website yang dikelola oleh pihak ketiga (“Situs
                      Eksternal”) yang melakukan kerjasama dengan Kami.
                    </li>
                    <li>
                      Kami tidak mengoperasikan, mengendalikan atau mendukung
                      dalam bentuk apapun Situs Eksternal yang bersangkutan
                      ataupun konten/isinya. Anda bertanggung jawab penuh atas
                      penggunaan Situs Eksternal tersebut dan dianjurkan untuk
                      mempelajari syarat dan ketentuan dari Situs Eksternal itu
                      secara seksama.
                    </li>
                    <li>
                      Layanan yang tersedia dalam Website ini secara umum
                      menggunakan sistem re-marketing dan sistem cookies yang
                      memungkinkan pihak ketiga (termasuk dan tidak terbatas
                      pada Google) mengakses dan menggunakan data kunjungan
                      dalam sistem Cookies website ini untuk menampilkan dan
                      menayangkan kembali tiap iklan Narasi melalui internet.
                    </li>
                    <li>
                      Anda tidak boleh membuat link, melakukan screen capture
                      atau data crawling ke Website tanpa adanya persetujuan
                      tertulis sebelumnya dari Narasi. Hal-hal tersebut dianggap
                      sebagai pelanggaran hak milik intelektual Narasi.
                    </li>
                  </ul>

                  <h2 class="sub-title">Konten</h2>
                  <ul>
                    <li>
                      Semua materi di dalam Website tidak boleh disalin,
                      diproduksi ulang, dipublikasikan, dan disebarluaskan baik
                      berupa informasi, data, gambar, foto, logo dan lainnya
                      secara utuh maupun sebagian dengan cara apapun dan melalui
                      media apapun kecuali untuk keperluan pribadi tanpa
                      persetujuan tertulis dari Narasi.
                    </li>
                    <li>
                      Website ini berikut seluruh materinya tidak boleh
                      dimanfaatkan untuk hal-hal yang bertujuan melanggar hukum
                      atau norma agama dan masyarakat atau hak asasi manusia.
                    </li>
                  </ul>

                  <h2 class="sub-title">
                    Pencabutan akses dan Penghapusan data
                  </h2>
                  <ul>
                    <li>
                      Untuk menghapus akses pihak ketiga, data dan aktivitas
                      anda di Narasi.tv, anda dapat mengajukan permintaan
                      penghapusan data ke halo@narasi.tv.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_pedoman_media_siber"
              aria-expanded="false"
              aria-controls="collapse_pedoman_media_siber"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'pedoman-media-siber',
                  },
                })
              "
            >
              <TitleSection title="PEDOMAN MEDIA SIBER" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_pedoman_media_siber"
              :class="{ show: $route.query.menu === 'pedoman-media-siber' }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="pedoman-media-siber" class="accordion-content">
                  <p class="desc">
                    Kemerdekaan berpendapat, kemerdekaan berekspresi, dan
                    kemerdekaan pers adalah hak asasi manusia yang dilindungi
                    Pancasila, Undang-Undang Dasar 1945, dan Deklarasi Universal
                    Hak Asasi Manusia PBB. Keberadaan media siber di Indonesia
                    juga merupakan bagian dari kemerdekaan berpendapat,
                    kemerdekaan berekspresi, dan kemerdekaan pers.<br /><br />
                    Media siber memiliki karakter khusus sehingga memerlukan
                    pedoman agar pengelolaannya dapat dilaksanakan secara
                    profesional, memenuhi fungsi, hak, dan kewajibannya sesuai
                    Undang-Undang Nomor 40 Tahun 1999 tentang Pers dan Kode Etik
                    Jurnalistik. Untuk itu Dewan Pers bersama organisasi pers,
                    pengelola media siber, dan masyarakat menyusun Pedoman
                    Pemberitaan Media Siber sebagai berikut<br /><br />
                    Kemerdekaan berpendapat, kemerdekaan berekspresi, dan
                    kemerdekaan pers adalah hak asasi manusia yang dilindungi
                    Pancasila, Undang-Undang Dasar 1945, dan Deklarasi Universal
                    Hak Asasi Manusia PBB. Keberadaan media siber di Indonesia
                    juga merupakan bagian dari kemerdekaan berpendapat,
                    kemerdekaan berekspresi, dan kemerdekaan pers.<br /><br />
                    Media siber memiliki karakter khusus sehingga memerlukan
                    pedoman agar pengelolaannya dapat dilaksanakan secara
                    profesional, memenuhi fungsi, hak, dan kewajibannya sesuai
                    Undang-Undang Nomor 40 Tahun 1999 tentang Pers dan Kode Etik
                    Jurnalistik. Untuk itu Dewan Pers bersama organisasi pers,
                    pengelola media siber, dan masyarakat menyusun Pedoman
                    Pemberitaan Media Siber sebagai berikut
                  </p>

                  <h2 class="sub-title">Ruang Lingkup</h2>
                  <p class="desc">
                    Media Siber adalah segala bentuk media yang menggunakan
                    wahana internet dan melaksanakan kegiatan jurnalistik, serta
                    memenuhi persyaratan Undang-Undang Pers dan Standar
                    Perusahaan Pers yang ditetapkan Dewan Pers. Isi Buatan
                    Pengguna (User Generated Content) adalah segala isi yang
                    dibuat dan atau dipublikasikan oleh pengguna media siber,
                    antara lain, artikel, gambar, komentar, suara, video dan
                    berbagai bentuk unggahan yang melekat pada media siber,
                    seperti blog, forum, komentar pembaca atau pemirsa, dan
                    bentuk lain.
                  </p>

                  <h2 class="sub-title">Verifikasi dan Keberimbangan Berita</h2>
                  <p class="desc">
                    Pada prinsipnya setiap berita harus melalui verifikasi.
                    Berita yang dapat merugikan pihak lain memerlukan verifikasi
                    pada berita yang sama untuk memenuhi prinsip akurasi dan
                    keberimbangan.
                  </p>

                  <p class="desc">
                    Ketentuan dalam butir (a) di atas dikecualikan, dengan
                    syarat:
                  </p>
                  <ul>
                    <li>
                      Berita benar-benar mengandung kepentingan publik yang
                      bersifat mendesak;
                    </li>
                    <li>
                      Sumber berita yang pertama adalah sumber yang jelas
                      disebutkan identitasnya, kredibel dan kompeten;
                    </li>
                    <li>
                      Subyek berita yang harus dikonfirmasi tidak diketahui
                      keberadaannya dan atau tidak dapat diwawancarai;
                    </li>
                    <li>
                      Media memberikan penjelasan kepada pembaca bahwa berita
                      tersebut masih memerlukan verifikasi lebih lanjut yang
                      diupayakan dalam waktu secepatnya. Penjelasan dimuat pada
                      bagian akhir dari berita yang sama, di dalam kurung dan
                      menggunakan huruf miring.
                    </li>
                  </ul>

                  <p class="desc">
                    Setelah memuat berita sesuai dengan butir (c), media wajib
                    meneruskan upaya verifikasi, dan setelah verifikasi
                    didapatkan, hasil verifikasi dicantumkan pada berita
                    pemutakhiran (update) dengan tautan pada berita yang belum
                    terverifikasi.
                  </p>

                  <h2 class="sub-title">
                    Isi Buatan Pengguna (User Generated Content)
                  </h2>
                  <p class="desc">
                    Media siber wajib mencantumkan syarat dan ketentuan mengenai
                    Isi Buatan Pengguna yang tidak bertentangan dengan
                    Undang-Undang No. 40 tahun 1999 tentang Pers dan Kode Etik
                    Jurnalistik, yang ditempatkan secara terang dan jelas. Media
                    siber mewajibkan setiap pengguna untuk melakukan registrasi
                    keanggotaan dan melakukan proses log-in terlebih dahulu
                    untuk dapat mempublikasikan semua bentuk Isi Buatan
                    Pengguna. Ketentuan mengenai log-in akan diatur lebih
                    lanjut.
                  </p>

                  <p class="desc">
                    Dalam registrasi tersebut, media siber mewajibkan pengguna
                    memberi persetujuan tertulis bahwa Isi Buatan Pengguna yang
                    dipublikasikan:
                  </p>
                  <ul>
                    <li>Tidak memuat isi bohong, fitnah, sadis dan cabul;</li>
                    <li>
                      Tidak memuat isi yang mengandung prasangka dan kebencian
                      terkait dengan suku, agama, ras, dan antargolongan (SARA),
                      serta menganjurkan tindakan kekerasan;
                    </li>
                    <li>
                      Tidak memuat isi diskriminatif atas dasar perbedaan jenis
                      kelamin dan bahasa, serta tidak merendahkan martabat orang
                      lemah, miskin, sakit, cacat jiwa, atau cacat jasmani.
                    </li>
                  </ul>

                  <p class="desc">
                    Media siber wajib menyediakan mekanisme pengaduan Isi Buatan
                    Pengguna yang dinilai melanggar ketentuan pada butir (c).
                    Mekanisme tersebut harus disediakan di tempat yang dengan
                    mudah dapat diakses pengguna.<br /><br />
                    Media siber wajib menyunting, menghapus, dan melakukan
                    tindakan koreksi setiap Isi Buatan Pengguna yang dilaporkan
                    dan melanggar ketentuan butir (c), sesegera mungkin secara
                    proporsional selambat-lambatnya 2 x 24 jam setelah pengaduan
                    diterima.<br /><br />
                    Media siber yang telah memenuhi ketentuan pada butir (a),
                    (b), (c), dan (f) tidak dibebani tanggung jawab atas masalah
                    yang ditimbulkan akibat pemuatan isi yang melanggar
                    ketentuan pada butir (c).<br /><br />
                    Media siber bertanggung jawab atas Isi Buatan Pengguna yang
                    dilaporkan bila tidak mengambil tindakan koreksi setelah
                    batas waktu sebagaimana tersebut pada butir (f).
                  </p>

                  <h2 class="sub-title">Ralat, Koreksi, dan Hak Jawab</h2>
                  <p class="desc">
                    Ralat, koreksi, dan hak jawab mengacu pada Undang-Undang
                    Pers, Kode Etik Jurnalistik, dan Pedoman Hak Jawab yang
                    ditetapkan Dewan Pers.
                    <br /><br />
                    Ralat, koreksi dan atau hak jawab wajib ditautkan pada
                    berita yang diralat, dikoreksi atau yang diberi hak jawab.
                    <br /><br />
                    Di setiap berita ralat, koreksi, dan hak jawab wajib
                    dicantumkan waktu pemuatan ralat, koreksi, dan atau hak
                    jawab tersebut.
                    <br /><br />
                    Bila suatu berita media siber tertentu disebarluaskan media
                    siber lain, maka:
                  </p>
                  <ul>
                    <li>
                      Tanggung jawab media siber pembuat berita terbatas pada
                      berita yang dipublikasikan di media siber tersebut atau
                      media siber yang berada di bawah otoritas teknisnya;
                    </li>
                    <li>
                      Koreksi berita yang dilakukan oleh sebuah media siber,
                      juga harus dilakukan oleh media siber lain yang mengutip
                      berita dari media siber yang dikoreksi itu;
                    </li>
                    <li>
                      Media yang menyebarluaskan berita dari sebuah media siber
                      dan tidak melakukan koreksi atas berita sesuai yang
                      dilakukan oleh media siber pemilik dan atau pembuat berita
                      tersebut, bertanggung jawab penuh atas semua akibat hukum
                      dari berita yang tidak dikoreksinya itu.
                    </li>
                  </ul>

                  <p class="desc">
                    Sesuai dengan Undang-Undang Pers, media siber yang tidak
                    melayani hak jawab dapat dijatuhi sanksi hukum pidana denda
                    paling banyak Rp500.000.000 (Lima ratus juta rupiah).
                  </p>

                  <h2 class="sub-title">Pencabutan Berita</h2>
                  <p class="desc">
                    Berita yang sudah dipublikasikan tidak dapat dicabut karena
                    alasan penyensoran dari pihak luar redaksi, kecuali terkait
                    masalah SARA, kesusilaan, masa depan anak, pengalaman
                    traumatik korban atau berdasarkan pertimbangan khusus lain
                    yang ditetapkan Dewan Pers.
                    <br /><br />
                    Media siber lain wajib mengikuti pencabutan kutipan berita
                    dari media asal yang telah dicabut.
                    <br /><br />
                    Pencabutan berita wajib disertai dengan alasan pencabutan
                    dan diumumkan kepada publik.
                  </p>

                  <h2 class="sub-title">Iklan</h2>
                  <p class="desc">
                    Media siber wajib membedakan dengan tegas antara produk
                    berita dan iklan. Setiap berita/artikel/isi yang merupakan
                    iklan dan atau isi berbayar wajib mencantumkan keterangan
                    'advertorial', 'iklan', 'ads', 'sponsored', atau kata lain
                    yang menjelaskan bahwa berita/artikel/isi tersebut adalah
                    iklan.
                  </p>

                  <h2 class="sub-title">Hak Cipta</h2>
                  <p class="desc">
                    Media siber wajib menghormati hak cipta sebagaimana diatur
                    dalam peraturan perundang-undangan yang berlaku.
                  </p>

                  <h2 class="sub-title">Pencantuman Pedoman</h2>
                  <p class="desc">
                    Media siber wajib mencantumkan Pedoman Pemberitaan Media
                    Siber ini di medianya secara terang dan jelas.
                  </p>

                  <h2 class="sub-title">Sengketa</h2>
                  <p class="desc">
                    Penilaian akhir atas sengketa mengenai pelaksanaan Pedoman
                    Pemberitaan Media Siber ini diselesaikan oleh Dewan Pers.
                    <br /><br />
                    Jakarta, 3 Februari 2012 (Peraturan Dewan Pers Nomor:
                    1/Peraturan-DP/III/2012 Tentang Pedoman Pemberitaan Media
                    Siber)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_kontak_kami"
              aria-expanded="false"
              aria-controls="collapse_kontak_kami"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'kontak-kami',
                  },
                })
              "
            >
              <TitleSection title="KONTAK KAMI" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_kontak_kami"
              :class="{ show: $route.query.menu === 'kontak-kami' }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="kontak-kami" class="accordion-content">
                  <div class="desc">
                    <p class="organitation">PT NARASI CITRA SAHWAHITA</p>
                    <span>INTILAND TOWER LT. 20</span>
                    <p>Jl. Jend. Sudirman Kav. 32, Jakarta Pusat, 10220</p>
                    <p class="m-0">
                      <strong
                        >Telp:
                        <a href="tel:02157939176">021-57930649</a></strong
                      >
                    </p>
                    <!-- <p class="m-0">
                      <strong
                        >Fax: <a href="fax:02157930649">021-57930649</a></strong
                      >
                    </p> -->
                    <p class="m-0">
                      <strong
                        >Email:
                        <a href="mailto:corcomm@narasi.tv"
                          >corcomm@narasi.tv</a
                        ></strong
                      >
                    </p>
                    <p>
                      Networking and Communications Management System Narasi
                    </p>
                  </div>
                  <div class="social-media">
                    <a
                      href="https://www.instagram.com/wmnlyfe/"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">WMN By <br />Narasi</p>
                    </a>
                    <a
                      href="https://www.instagram.com/komunitasnarasi/"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Komunitas <br />Narasi</p>
                    </a>
                    <a
                      href="https://www.instagram.com/narasi.ecosystem/"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Narasi <br />Ecosystem</p>
                    </a>
                    <a
                      href="https://www.instagram.com/ceritadinarasi/"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Cerita Di <br />Narasi</p>
                    </a>
                    <!-- <a
                      href="https://www.instagram.com/getstarted.narasi/?hl=en"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Get Started <br />Narasi</p>
                    </a> -->
                    <a
                      href="https://www.instagram.com/narasiacademy/"
                      target="_blank"
                      rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Narasi <br />Academy</p>
                    </a>
                    <!-- <a
                      href="https://www.instagram.com/narasipeople/?hl=en"
                      target="_blank" rel="noopener"
                      class="item"
                    >
                      <Icons name="instagram-about-us" />
                      <p class="desc">Narasi <br />People</p>
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              class="accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse_karir"
              aria-expanded="false"
              aria-controls="collapse_karir"
              @click="
                $router.push({
                  name: 'about-us',
                  query: {
                    menu: 'karir',
                  },
                })
              "
            >
              <TitleSection title="KARIR" />
              <Icons name="arrow-bottom" class="arrow-bottom" />
            </div>
            <div
              id="collapse_karir"
              :class="{ show: $route.query.menu === 'karir' }"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-about-us"
            >
              <div class="accordion-body">
                <div id="karir" class="accordion-content">
                  <NuxtLink to="/karir" class="btn-karir">
                    FULLTIME POSITION
                    <Icons name="bag" />
                  </NuxtLink>
                  <NuxtLink to="/karir" class="btn-karir">
                    INTERN POSITION
                    <Icons name="bag"
                  /></NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config";
import CardFive from "/components/cards/CardFive.vue";
import Icons from "/components/Icons";
import ImageResponsiveBanner from "/components/ImageResponsiveBanner";
import TitleSection from "/components/TitleSection";

const menus = [
  {
    label: "INFORMASI PERUSAHAAN",
    slug: "informasi-perusahaan",
    description:
      "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
  },
  // {
  //   label: "PRESS RELEASE",
  //   slug: "press-release",
  //   description: "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda."
  // },
  {
    label: "PRIVASI",
    slug: "privasi",
    description:
      "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
  },
  {
    label: "SYARAT DAN KETENTUAN UMUM",
    slug: "syarat-dan-ketentuan-umum",
    description:
      "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
  },
  {
    label: "PEDOMAN MEDIA SIBER",
    slug: "pedoman-media-siber",
    description:
      "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
  },
  {
    label: "KONTAK KAMI",
    slug: "kontak-kami",
    description:
      "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
  },
  {
    label: "KARIR",
    slug: "karir",
    description:
      "Mari bertumbuh bersama membangun negeri, jadilah bagian dari Narasi TV.",
  },
];
export default {
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: route.query.menu
          ? menus.filter((item) => item.slug === route.query.menu)[0].label
          : "About Us",
        desc: route.query.menu
          ? menus.filter((item) => item.slug === route.query.menu)[0]
              .description
          : "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    TitleSection,
    ImageResponsiveBanner,
    CardFive,
    Icons,
  },
  data() {
    return {
      menus,
      // googleInit: null,
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  methods: {
    redirect(link) {
      window.location.href = this.$router.resolve(link).href;
    },
  },
  // mounted() {
  //   this.googleInit = setTimeout(() => {
  //     if (typeof window !== "undefined") {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     }
  //   }, 200);
  // },
  // destroyed() {
  //   if (this.googleInit) {
  //     clearTimeout(this.googleInit);
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.informasi-perusahaan {
  .banner {
    .image {
      width: 100%;
      height: auto;
    }
  }
  .content {
    padding: 32px 0 48px;
    @media only screen and (max-width: 1024px) {
      padding: 0 0 48px;
    }
    &.is-desktop {
      .container {
        display: flex;
        .content-nav {
          width: 30%;
          display: flex;
          flex-direction: column;
          .menu-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 0;
            border-bottom: 1px solid rgb(189, 189, 189, 0.5);
            &:first-child {
              padding-top: 0;
            }
            .title {
              color: #616161;
            }
            &.Link-exact-active {
              .title {
                color: #4a25aa;
              }
            }
            svg {
              width: 30px;
              height: 30px;
            }
          }
        }
        .content-detail {
          width: 70%;
          padding-left: 48px;
          .title {
            font-size: 20px;
            font-weight: 700;
            color: #4a25aa;
            margin-bottom: 24px;
          }
          .sub-title {
            font-size: 16px;
            font-weight: 700;
            color: #0c0b0d;
            margin-bottom: 8px;
          }
          .desc {
            font-size: 14px;
            line-height: 20px;
            color: #0c0b0d;
            margin: 0;
          }
          ul {
            li {
              font-size: 14px;
              color: #0c0b0d;
              line-height: 28px;
            }
          }
          #informasi-perusahaan {
            .about {
              margin-bottom: 48px;
            }
            .redaksi {
              margin-bottom: 48px;
              .pt {
                margin-bottom: 32px;
              }
            }
          }
          #press-release {
            margin-bottom: 32px;
            .thumbnail {
              margin-bottom: 32px;
              .image {
                margin-bottom: 16px;
                border-radius: 6px;
              }
              .category-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .text {
                  font-size: 14px;
                  color: #4a25aa;
                  margin: 0;
                }
                span {
                  font-size: 15px;
                  line-height: 15px;
                  margin: 0 10px;
                }
              }
              .title {
                font-size: 24px;
                font-weight: 500;
                color: #0c0b0d;
                margin: 0;
              }
            }
          }
          #privasi {
            .desc {
              margin-bottom: 24px;
            }
            ul {
              list-style-type: decimal;
            }
          }
          #syarat-dan-ketentuan-umum {
            .sub-title {
              text-align: center;
            }
            ul {
              list-style-type: decimal;
            }
          }
          #pedoman-media-siber {
            .desc {
              margin-bottom: 24px;
            }
          }
          #kontak-kami {
            .organitation {
              font-size: 14px;
              font-weight: 500;
            }
            .desc {
              margin-bottom: 32px;
              p {
                line-height: 20px;
              }
            }
            .social-media {
              display: flex;
              max-width: 300px;
              flex-wrap: wrap;

              .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 32px;
                svg {
                  width: 40px;
                  height: 40px;
                  margin-bottom: 8px;
                }
                .desc {
                  text-align: center;
                }
              }
            }
          }
          #karir {
            display: flex;
            flex-direction: column;
            .btn-karir {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #4a25aa;
              color: #fafafa;
              font-size: 14px;
              font-weight: 700;
              position: relative;
              width: 327px;
              height: 50px;
              border-radius: 6px;
              margin-bottom: 16px;
              &:hover {
                background: #8166c8;
                color: #fafafa !important;
              }
              &:active {
                background: #6242b4;
              }
              svg {
                width: 15px;
                height: auto;
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    &.is-mobile {
      .container {
        #accordion-about-us {
          .accordion-item {
            background: transparent;
            border: none;
            border-bottom: 1px solid rgb(189, 189, 189, 0.5);
            .accordion-btn {
              padding: 16px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .accordion-collapse {
              .accordion-body {
                padding-top: 0;
                .accordion-content {
                  padding-top: 16px;
                  border-top: 1px solid rgba(189, 189, 189, 0.5);
                }
                .title {
                  font-size: 14px;
                  font-weight: 700;
                  color: #4a25aa;
                  margin-bottom: 10px;
                }
                .sub-title {
                  font-size: 14px;
                  font-weight: 700;
                  color: #0c0b0d;
                  margin-bottom: 6px;
                }
                .desc {
                  font-size: 12px;
                  line-height: 20px;
                  color: #0c0b0d;
                  margin: 0;
                  p {
                    line-height: 20px;
                  }
                }
                ul {
                  li {
                    font-size: 12px;
                    color: #0c0b0d;
                    line-height: 24px;
                  }
                }
                #informasi-perusahaan {
                  .about {
                    margin-bottom: 48px;
                  }
                  .redaksi {
                    margin-bottom: 48px;
                    .pt {
                      margin-bottom: 32px;
                    }
                  }
                }
                #press-release {
                  margin-bottom: 32px;
                  .thumbnail {
                    margin-bottom: 32px;
                    .image {
                      margin-bottom: 16px;
                      border-radius: 6px;
                    }
                    .category-wrapper {
                      display: flex;
                      align-items: center;
                      margin-bottom: 8px;
                      .text {
                        font-size: 12px;
                        color: #4a25aa;
                        margin: 0;
                      }
                      span {
                        font-size: 10px;
                        line-height: 10px;
                        margin: 0 8px;
                      }
                    }
                    .title {
                      font-size: 16px;
                      font-weight: 500;
                      color: #0c0b0d;
                      margin: 0;
                    }
                  }
                }
                #privasi {
                  .desc {
                    margin-bottom: 24px;
                  }
                  ul {
                    list-style-type: decimal;
                  }
                }
                #syarat-dan-ketentuan-umum {
                  .sub-title {
                    text-align: center;
                  }
                  ul {
                    list-style-type: decimal;
                  }
                }
                #pedoman-media-siber {
                  .desc {
                    margin-bottom: 24px;
                  }
                }
                #kontak-kami {
                  .desc {
                    margin-bottom: 32px;
                  }
                  .social-media {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    .item {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin-right: 32px;
                      &:nth-child(1n + 4) {
                        .desc {
                          margin-bottom: 0;
                        }
                      }
                      svg {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 8px;
                      }
                      .desc {
                        text-align: center;
                      }
                    }
                  }
                }
                #karir {
                  display: flex;
                  flex-direction: column;
                  .btn-karir {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #4a25aa;
                    color: #fafafa;
                    font-size: 14px;
                    font-weight: 700;
                    position: relative;
                    width: 327px;
                    height: 50px;
                    border-radius: 6px;
                    margin-bottom: 16px;
                    @media only screen and (max-width: 1024px) {
                      width: 100%;
                    }
                    &:hover {
                      color: #fafafa !important;
                    }
                    &:active {
                      background: #6242b4;
                    }
                    svg {
                      width: 15px;
                      height: auto;
                      position: absolute;
                      right: 16px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
